<template>
    <el-container>
        <el-main class="main-container">
            <Main />
        </el-main>
        <el-backtop target=".main-container"></el-backtop>
    </el-container>
    
</template>
<script>
import Main from "./main.vue"
export default {
    name: 'layout',
    data() {
        return {

        }
    },
    components: {
        Main
    },
    methods: {

    },
    mounted() {

    }
}

</script>


<style lang="scss" scoped>


.el-header {
    color: #fff;
}

.el-main {
    background-color: #fff;
    height:100vh;
    flex-grow: 1;
    padding: 0 !important;
    overflow: auto;
}
</style>