<template>
    <div>
        <Header></Header>
        <router-view :key="key"></router-view>
    </div>
</template>
<script>
import Header from './header.vue'
export default {
    name: 'mainView',
    components: {
        Header
    },
    computed: {
        key() {
            return this.$route.path;
        }
    }
}

</script>


<style lang="scss" scoped></style>