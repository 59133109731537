<template>
    <div>
        <div class="container">
            <div class="nav">
                <div class="nav-top">
                    <p><span>Buzón de servicio：officiallytop6@gmail.com</span>
                    </p>
                    <p></p>
                    <p class="btn">Aplica ya</p>
                </div>
                <div class="nav-bottom"></div>
            </div>
        </div>
        <div class="header">
            <div class="header-item">
                <div class="header-logo"><img src="@/assets/logo.png" alt=""></div>
                <div>
                    <router-link :to="{ name: 'home' }">INICIO</router-link>
                    <router-link :to="{ name: 'info' }">TÉRMINOS Y CONDICIONES</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'headerView',

}

</script>


<style lang="scss" scoped>
.container {
    background: #046daa;
    color: #fff;
}

.nav {
    max-width: 1140px;
    padding: 5px 0;
    margin: 0 auto;

    .nav-top {
        display: flex;
        align-items: center;
        justify-content: space-around;

        >p:first-child {
            display: inline-block;
            position: relative;
            margin: 0 40px 0 0;
            color: #fff;
            font-size: 14px;

            span {
                margin-right: 20px;
            }
        }

        >p:nth-child(2) {
            flex: 1;
            text-align: right;
        }

        .btn {
            background: #1DEBE4;
            border: medium none;
            border-radius: 30px;
            color: #fff;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            height: auto;
            margin: auto;
            letter-spacing: 0.5px;
            padding: 3px 15px;
            transition: all 0.3s linear 0s;
            -moz-transition: all 0.3s linear 0s;
            -o-transition: all 0.3s linear 0s;
            -webkit-transition: all 0.3s linear 0;
            cursor: pointer;
        }
    }
}

.header {
    position: relative;
    z-index: 10;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    &-item {
        max-width: 1140px;
        height: 96px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
    }

    &-logo {
        width: 218px;
        height: 80px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    a {
        text-decoration: none;
        margin: 0 10px;
        color: #0a0f2b;
        font-size: 15px;
        font-weight: 400;
    }

    a:hover {
        color: #1DEBE4;
        transition: 0.5s;

    }
}

@media (max-width: 1140px) {
    .header {
        flex-direction: column;
        align-items: center;
    }
}
</style>